/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import { FunctionComponent } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as DatePickerMUI, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormValidationError, getErrorsForField } from '../../utils/validations';
import ErrorMessageBase from './ErrorMessage';
import CalendarIconPath from '../../assets/images/date-picker/calendar.svg';
import ArrowDownIconPath from '../../assets/images/date-picker/arrow_down.svg';
import { DatePickerQuickButtons } from '../../types/general';
import Button from './Button';
import TextBadge from './TextBadge';

type InputBaseAttributes = Partial<Pick<HTMLInputElement, 'required'|'tabIndex'>>

type OwnProps = InputBaseAttributes & {
    name: string;
    value?: string;
    format?: string;
    label?: string;
    onChange: (value: Date | null, field: string) => void;
    errors?: FormValidationError | null;
    quickButtons?: DatePickerQuickButtons[];
    onClickButtonChange?: (months: number) => void;
    extraClasses?: string;
}

export const CalendarIcon = () => (
    <img src={CalendarIconPath} alt="Calendar icon" />
);

export const ArrowDownIcon = () => (
    <img src={ArrowDownIconPath} alt="Arrow down icon" />
);

export const ArrowLeftIcon = () => (
    <img src={ArrowDownIconPath} className="rotate-90" alt="Arrow left icon" />
);

export const ArrowRightIcon = () => (
    <img src={ArrowDownIconPath} className="rotate--90" alt="Arrow right icon" />
);

const DatePicker: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        name,
        value,
        format = 'DD/MM/YYYY',
        label,
        onChange,
        errors,
        quickButtons,
        onClickButtonChange,
        extraClasses,
        required,
    } = props;

    const fieldErrors = getErrorsForField(name, errors);

    return (
        <div className="date-picker" data-testid={`date-picker-${name}`}>
            <div className="date-picker__top">
                {label ? <label htmlFor={name} className={`form-field-label ${required && 'required'}`}>{label}</label> : null}
            </div>
            <div className="date-picker__container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerMUI
                        name={name}
                        value={value ? dayjs(value) : null}
                        format={format}
                        onChange={(newValue) => onChange(new Date(String(newValue)), name)}
                        className={extraClasses}
                        slots={{
                            openPickerIcon: CalendarIcon,
                            leftArrowIcon: ArrowLeftIcon,
                            rightArrowIcon: ArrowRightIcon,
                            switchViewIcon: ArrowDownIcon,
                        }}
                        
                    />
                    {quickButtons && (
                        <div className="date-picker__container__quick-buttons">
                            {quickButtons.map((button) => {
                                return (
                                    <Button
                                        key={button.key}
                                        onClick={onClickButtonChange ? () => onClickButtonChange(button.key) : undefined}
                                    >
                                        <TextBadge text={button.value} />
                                    </Button>
                                );
                            })}
                        </div>
                    )}
                    {fieldErrors.length ? <ErrorMessageBase errors={fieldErrors} field={name} /> : null}
                </LocalizationProvider>
            </div>
        </div>
    );
};

export { DatePicker };
