/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 * For detailed information about this feature and limitations
 * take a look on SHARE_COMPONENT_README.md
 */

import { FunctionComponent, ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Button from './Button';
import { ButtonVariant, SharingUtilsType } from '../../types/general';
import { ReactComponent as UnlockitAvatar } from '../../assets/images/unlockit-avatar.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/share.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/images/whatsapp.svg';
import { ReactComponent as TelegramIcon } from '../../assets/images/telegram.svg';
import { ReactComponent as MoreOptionsIcon } from '../../assets/images/more-options.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Modal from './Modal';
import { getSharingLink } from '../../utils/sharingUrls';

interface OwnProps extends TranslationContext {
    customIcon?: ReactNode;
    text: string;
    url: string;
}

const ShareComponentBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        customIcon,
        text,
        url,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isNativeShareOpen, setIsNativeShareOpen] = useState(false);

    /**
     * Native Share
     */
    const nativeShare = async () => {
        try {
            await navigator.share({ title: text, url });
            setIsNativeShareOpen((prev) => !prev);
        } catch (error: unknown) {
            handleNativeShareError(error);
        }
    };
    /**
     * Handle Native Share Errors
     *
     * For more details take a look on SHARE_COMPONENT_README.md
     *
     * @param error
     */
    const handleNativeShareError = (error: unknown) => {
        switch ((error as DOMException)?.name) {
            case 'AbortError':
            case 'InvalidStateError':
                setIsNativeShareOpen(false);
                break;
            default:
                toast.error(t('errors.nativeShare'));
                break;
        }
    };
    return (
        <div className="share-component">
            <Button variant={ButtonVariant.Circle} extraClasses="share-component__btn secondary" onClick={() => setIsOpen(true)} disabled={isOpen} testId="share-component">
                {customIcon ?? <ShareIcon />}
            </Button>
            <Modal
                open={isOpen}
                title={t('signerActions.shareTitle')}
                handleClose={() => setIsOpen(false)}
                extraClasses="share-component__modal"
            >
                <div className="share-component__modal__share-info">
                    <UnlockitAvatar />
                    <div className="share-component__modal__share-info__text">
                        <div className="share-component__modal__share-info__text__title">{text}</div>
                        <div className="share-component__modal__share-info__text__url">{url}</div>
                    </div>
                </div>
                <h4>{t('signerActions.shareByApp')}</h4>
                <div className="share-component__modal__share-options">
                    <Link to={getSharingLink(SharingUtilsType.WHATSAPP, text, url)} target="_blank">
                        <Button
                            variant={ButtonVariant.IconBtn}
                            extraClasses="secondary large basic-border-radius whatsapp"
                            helperText={t('signerActions.whatsApp')}
                            testId="whatsapp-button"
                        >
                            <WhatsappIcon />
                        </Button>
                    </Link>
                    <Link to={getSharingLink(SharingUtilsType.TELEGRAM, text, url)} target="_blank">
                        <Button
                            variant={ButtonVariant.IconBtn}
                            extraClasses="secondary large basic-border-radius telegram"
                            helperText={t('signerActions.telegram')}
                            testId="telegram-button"
                        >
                            <TelegramIcon />
                        </Button>
                    </Link>
                </div>
                <div className="share-component__modal__more-options">
                    {!!navigator.share && (
                        <Button
                            variant={ButtonVariant.RectangularFilled}
                            extraClasses="secondary full-width"
                            onClick={nativeShare}
                            disabled={isNativeShareOpen}
                            endIcon={<MoreOptionsIcon />}
                            testId="native-share-button"
                        >
                            {t('signerActions.otherOptions')}
                        </Button>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export const ShareComponent = withTranslationContext(ShareComponentBase);
