/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { SharingUtilsType } from '../types/general';

export const getSharingLink = (sharingType: SharingUtilsType, text: string, url: string) => {
    const encodeTextAndUrl = encodeURIComponent(`${text}\n\n${url}`);

    switch (sharingType) {
        case SharingUtilsType.WHATSAPP:
            return `https://wa.me/?text=${encodeTextAndUrl}`;
        case SharingUtilsType.TELEGRAM:
            return `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
        default:
            return '';
    }
};
